import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTheme } from '../../context/ThemeContext';
import Logo from './Logo';
import NavLinks from './NavLinks';
import SearchBar from './SearchBar';
import UserActions from './UserActions/UserActions';

const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: ${props => props.theme.headerBg};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;


const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 20px; */
  padding: 10px 0px 0px 0px;
  margin: 0 auto;
  width: 100%;
  /* max-width: 1200px; */
  max-width: 90%;
  color: ${props => props.theme.headerText};

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

const NavLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 2;

  @media (max-width: 768px) {
    order: 3;
    width: 100%;
    justify-content: center;
  }
`;

const SearchContainer = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    order: 2;
    justify-content: center;
  }
`;

const UserActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;

  @media (max-width: 768px) {
    order: 4;
    width: 100%;
    justify-content: center;
  }
`;

const Header = () => {
  const { theme } = useTheme();
  const headerRef = useRef(null);

  useEffect(() => {
    // Keeping the effect for potential future needs
  }, []);

  return (
    <HeaderWrapper>
      <HeaderContainer ref={headerRef}>
        <LogoContainer>
          <Logo logoSrc={theme.logo} />
        </LogoContainer>
        <NavLinksContainer>
          <NavLinks />
        </NavLinksContainer>
        <SearchContainer>
          <SearchBar />
        </SearchContainer>
        <UserActionsContainer>
          <UserActions />
        </UserActionsContainer>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;


// import React, { useRef, useEffect } from 'react';
// import styled from 'styled-components';
// import { useTheme } from '../../context/ThemeContext';
// import Logo from './Logo';
// import NavLinks from './NavLinks';
// import SearchBar from './SearchBar';
// import UserActions from './UserActions/UserActions';


// const HeaderWrapper = styled.div`
//   position: fixed;
//   width: 100%;
//   top: 0;
//   z-index: 1000;
//   background-color: ${props => props.theme.headerBg};
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
// `;

// const HeaderContainer = styled.header`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0px 0px 0px 0px;
//   margin: 0 auto;
//   /* max-width: 1300px;  */
//   max-width: 90%;
//   width: 100%;
//   color: ${props => props.theme.headerText};
//   height: auto;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     align-items: flex-start;
//     padding: 10px;
//     max-width: 100%;
//   }
// `;

// const Header = () => {
//   const { theme } = useTheme();
//   const headerRef = useRef(null);

//   useEffect(() => {
//     // keeping the effect for potential future needs
//   }, []);

//   return (
//     <HeaderWrapper>
//       <HeaderContainer ref={headerRef}>
//         <Logo logoSrc={theme.logo} />
//         <NavLinks />
//         <SearchBar />
//         <UserActions />
//       </HeaderContainer>
//     </HeaderWrapper>
//   );
// };

// export default Header;