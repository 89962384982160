import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Privacy Policy</h1>
      <p>
        This is the Privacy Policy page. Add your privacy policy details here.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
