import React from 'react';

const TermsAndConditions = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Terms and Conditions</h1>
      <p>
        This is the Terms and Conditions page. Add your terms and conditions details here.
      </p>
    </div>
  );
};

export default TermsAndConditions;
